// 封装对应request请求
import { LOGINTOKEN } from '@/constants'
import { message } from 'antd'

import axios, { InternalAxiosRequestConfig } from 'axios'

const request = axios.create({
  timeout: 5000,
  baseURL: '/v1/api'
})

const getSsItem = (key: string) => {
  return sessionStorage.getItem(key) || ''
}

// 自动携带token
request.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    config.headers.timeZoneOffset = new Date().getTime()

    const token = getSsItem(LOGINTOKEN)
    if (token) {
      config.headers.Authorization = token
    }
    return config
  },
  (error) => [console.log(error)]
)

request.interceptors.response.use(
  (response) => {
    const res = response.data
    if (res) {
      if (res.code === 200) {
        return Promise.resolve(res.data)
      } else {
        message.error(res.message)
        return Promise.reject(res.message)
      }
    } else if (!res) {
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.data)
    }
  },
  (error) => {
    const { response } = error
    if (response.status === 403) {
      location.href = '/login'
    } else {
      console.log(error)
      return Promise.reject(error.data)
    }
  }
)

export { request }
