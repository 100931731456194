import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface IInitialState {
  [key: string]: any
}

const initialState: IInitialState = { themeColor: 'red' }

const counterSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setColor(state, action: any) {
      state.themeColor = action.color
    },
    setTitle(state, action: any) {
      state.title = action.payload.title
    }
  }
})

export const { setColor, setTitle } = counterSlice.actions
export default counterSlice.reducer
