import React from 'react'
import ReactDOM from 'react-dom/client'
import '@/style/index.less'
import 'normalize.css'
import RouteMain from '@/routes/main'
import store from './store'
import { Provider } from 'react-redux'
import { getLocal } from './utils/locale'
import 'antd/dist/reset.css'
import 'animate.css'
import { configure } from 'mobx'
configure({ enforceActions: 'always' })

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <RouteMain />
  </Provider>
  // </React.StrictMode>
)
