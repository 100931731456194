// 后台的入口

import { lazyLoad } from '@/component/utils'
// 全局控制是否需要token的相关变量
import { needToken } from '@/constants'
import { RouteObject } from 'react-router-dom'

// 后台管理中心布局
export const ManagerLayout = lazyLoad({
  loader: () => import(/* webpackChunkName: 'manager' */ './layout/index'),
  needToken
})

export const managerRoutes: RouteObject[] = [
  {
    path: '/info',
    element: <ManagerLayout />,
    children: [
      {
        path: ':menu',
        element: <>11</>
      }
    ]
  }
]
