import { createAction, createReducer } from '@reduxjs/toolkit'

interface IUserState {
  [key: string]: any
}

const updateUserName = createAction('user/updateUserName')
const updateUserId = createAction('user/updateUserId')
const updateUserAge = createAction<number>('user/updateUserAge')

const updateUserInfo = createAction('user/updateUserInfo')

const initialState: IUserState = {
  user_name: '',
  permissions: ''
}

const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateUserName, (state: any, action: any) => {
      const { user } = action
      state.user_name = user.user_name
      state.user_id = user.user_id
    })
    .addCase(updateUserId, (state: any, action: any) => {
      state.id = action.id
    })
    .addCase(updateUserAge, (state: any, action: any) => {
      state.age = action.age
    })
    .addCase(updateUserInfo, (state: any, action: any) => {
      state.name = action.info.name
      state.id = action.info.id
      state.age = action.info.age
    })
})
export default userReducer
export { updateUserName, updateUserId, updateUserAge, updateUserInfo }
