import { request } from '@/utils/request/index'
import md5 from 'md5'

export const baseLogin = async (data: any) => {
  const { password, user_name, ...others } = data
  const md5Passpord = md5(password || '')
  return request.post('/user/login', {
    ...others,
    user_name,
    password: md5Passpord
  })
}

export const loginCheck = async () => {
  return request.post('/login/loginCheck')
}

// 登录之前的校验
export const registerCheck = async (data: any) => {
  return request.post('/register/check', data)
}

export const userRegister = async (data: any) => {
  const { password, user_name } = data

  const md5Passpord = md5(password || '')
  return request.post('/register', {
    user_name,
    password: md5Passpord
  })
}

export default {}
