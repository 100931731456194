import { loginCheck } from '@/api/base'
import { LOGINTOKEN } from '@/constants'
import {
  mapDispatchToProps,
  mapStateToProps
} from '@/pages/backstage/store/user'
import { updateUserName } from '@/store/user'
import { ReactElement, Component, useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import store from '../store/index'
let isFirstCheck = true

export const withLoginChecked = (Cpm: () => ReactElement) => {
  const Middle = () => {
    const loginToken = sessionStorage.getItem(LOGINTOKEN) || ''

    const navigate = useNavigate()
    useEffect(() => {
      if (loginToken && isFirstCheck) {
        loginCheck()
          .then((data) => {
            if (data) {
              store.dispatch({ type: 'user/updateUserName', user: data })
              isFirstCheck = false
            }
          })
          .catch(() => {
            isFirstCheck = true
          })
      } else if (!loginToken) {
        navigate('/login')
      }
    }, [])

    if (loginToken) {
      return <Cpm />
    } else {
      return null
    }
  }
  return Middle
}
