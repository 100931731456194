import React from 'react'
import { useParams, useLocation, useSearchParams } from 'react-router-dom'
export default function PhotoAlter() {
  const params = useParams()
  const location = useLocation()
  const [search, fn] = useSearchParams()
  console.log(params)
  console.log(location)
  console.log(search)
  return (
    <div>
      PhotoAlter
      <div>{params?.id ? <>传递过来的id:{params.id}</> : null}</div>
      <div>
        {location?.search ? <>传递过来的location:{location.search}</> : null}
      </div>
      <div>{search ? <>传递过来的id:{search.get('name')}</> : null}</div>
    </div>
  )
}
