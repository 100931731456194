import React, { useState, useCallback, useEffect, useContext } from 'react'
import { UserCtx, ThemeCtx, IUserContext } from '../context'
import { connect } from 'react-redux'
import { setColor } from '@/store/theme'

// const

const mapStateToProps = (state: any) => {
  return {
    name: state.user.name,
    age: state.user.age,
    id: state.user.id,
    themeColor: state.theme.themeColor
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateName: (name: any) => dispatch({ type: 'user/updateUserName', name }),
    updateId: (id: any) => dispatch({ type: 'user/updateUserId' }, id),
    updateAge: (age: any) => dispatch({ type: 'user/updateUserAge', age }),
    updateInfo: (info: any) => dispatch({ type: 'user/updateUserInfo', info }),
    setColor: (color: any) => dispatch({ type: 'theme/setColor', color }),
    setColorFunc: () => dispatch(setColor, 'red') //不生效有问题
  }
}

function ChildA(props: any) {
  const userCtx = useContext(UserCtx)
  const [username, setUsername] = useState('')
  // console.log(userCtx)
  const handleChange = (e: {
    target: { value: React.SetStateAction<string> }
  }) => {
    const name = e.target.value
    setUsername(name)
  }

  const hanldeChangeCtx = () => {
    userCtx.changeValue({
      name: username
    })
  }

  return (
    <>
      子组件A
      <p>使用上下文数据: 姓名: {userCtx.name}</p>
      <p>使用上下文数据: id: {userCtx.id}</p>
      <p>使用上下文数据: 年龄: {userCtx.age}</p>
      <p>
        <input
          title="name"
          type="text"
          value={username}
          onChange={handleChange}
        />
        <button onClick={hanldeChangeCtx}>修改上下文年龄数据</button>
      </p>
    </>
  )
}

const PhotoAdd: React.FC = (props: any) => {
  // 设置用户的state
  const [userState, setUserState] = useState<IUserContext>({})
  // 设置主题的state
  const [themeState, setThemeState] = useState<IUserContext>({})
  const { name, age, id } = props

  useEffect(() => {
    setTimeout(() => {
      props.updateInfo({
        name: 'xpy',
        age: 27,
        id: 3505
      })
    }, 200)
  }, [])

  useEffect(() => {
    setUserState({
      name,
      age,
      id
    })
  }, [name, age, id])

  const changeValue = useCallback((value: any) => {
    setUserState({
      ...userState,
      ...value
    })
  }, [])
  const changeThemeValue = useCallback((value: any) => {
    setThemeState({
      ...themeState,
      ...value
    })
  }, [])
  return (
    <>
      <UserCtx.Provider
        value={{
          ...userState,
          changeValue
        }}
      >
        <ThemeCtx.Provider
          value={{
            ...themeState,
            changeThemeValue
          }}
        >
          <div>
            个人用户信息:
            <p>姓名: {props.name}</p>
            <p>年龄: {props.age}</p>
            <p>id: {props.id}</p>
          </div>
          <div>
            主题信息：
            <p>主题颜色：{props.themeColor}</p>
            <p>
              <button
                onClick={() => {
                  props.setColor('blue')
                }}
              >
                修改主题颜色
              </button>
              <button
                onClick={() => {
                  props.setColorFunc('blue')
                }}
              >
                通过action修改主题颜色
              </button>
            </p>
          </div>
          <ChildA></ChildA>
        </ThemeCtx.Provider>
      </UserCtx.Provider>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotoAdd)
