import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
export const fetchDataAction = createAsyncThunk('fetHomede', async () => {
  const ret = await axios.get('/api/demo')
  console.log(ret)
  return ret.data
})

const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    counter: 888
  },
  reducers: {
    addNumber(state, { payload }) {
      state.counter = state.counter + payload
    },
    minuNumber(state, { payload }) {
      state.counter = state.counter - payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDataAction.fulfilled, (state, action) => {
      // Add user to the state array
      state.counter = 10086
    })
  }
})

export const { addNumber, minuNumber } = counterSlice.actions
export default counterSlice.reducer
