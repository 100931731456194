import React, { Component, Suspense } from 'react'
import { BrowserRouter as Router, useRoutes } from 'react-router-dom'
import RouteConfig from './index'

const RouteMain: React.FC = () => {
  return useRoutes(RouteConfig)
}

const RouterWrapper: React.FC = () => {
  return (
    <Router>
      <RouteMain></RouteMain>
    </Router>
  )
}
/**
 *  两种导入方式 配置式 声明式
 <Router>
      <Routes>
        <Route element={<Home />} path="*" />
      </Routes>
    </Router>
 *
 */
export default RouterWrapper
