import { addNumberCreator, minusNumberCreator } from './actionCreator'
import { reducer } from './reducer'
import myReducer, { minuNumber, addNumber } from './counter'
import { configureStore } from '@reduxjs/toolkit'
import userReducer from './user'
import themeReducer from './theme'

const store = configureStore({
  reducer: {
    counter: reducer,
    number: myReducer,
    user: userReducer,
    theme: themeReducer
  }
  //   middleware: applyMiddleware()
})

// console.log(store.getState())

// store.dispatch(minuNumber(111))

// console.log(store.getState())

export default store

// store.dispatch(
//   addNumberCreator({
//     id: 1,
//     name: 'xpy'
//   })
// )

// console.log(store.getState())

// store.dispatch(
//   addNumberCreator([
//     { id: 2, name: 'wzd' },
//     { id: 3, name: 'ysh' }
//   ])
// )

// console.log(store.getState())

// store.dispatch(minusNumberCreator({ id: 1 }))

// console.log(store.getState())

// store.dispatch(minusNumberCreator([{ id: 1 }, { id: 2 }, { id: 111 }]))

// console.log(store.getState())
