/* eslint-disable no-case-declarations */
import * as constants from './constans'
interface IUserInfo {
  id: number
  name?: string
  age?: number
  // [_: string]: any
}
interface IInitState {
  num: number
  userInfo: Array<IUserInfo>
  otherMessage: string
}
const initState: IInitState = {
  num: 10,
  userInfo: [],
  otherMessage: 'tip'
}
interface IType {
  type: string
  payload?: any
}
export const reducer = (state: any = initState, action: IType) => {
  switch (action.type) {
    case constants.ADD:
      return { ...state, num: action.payload }
    case constants.MINUS:
      return { ...state, num: action.payload }
    case constants.ADD_NUMBER:
      if (Array.isArray(action.payload)) {
        let num = state.num
        let userInfo: Array<any> = state.userInfo
        num += action.payload.length
        userInfo = userInfo.concat(action.payload)
        return { ...state, num, userInfo }
      } else if (typeof action.payload === 'object') {
        let userInfo: Array<any> = state.userInfo
        userInfo = [...userInfo, action.payload]
        return { ...state, num: state.num + 1, userInfo }
      }
      return state
    case constants.MINUS_NUMBER:
      if (Array.isArray(action.payload)) {
        let userInfo: Array<any> = state.userInfo
        const idNumber = action.payload.map((item) => item.id)
        userInfo = userInfo.filter((item) => !idNumber.includes(item.id))
        return { ...state, num: userInfo.length, userInfo }
      } else if (typeof action.payload === 'object') {
        const userInfo: Array<any> = state.userInfo.filter(
          (item: IUserInfo) => item?.id !== action?.payload?.id
        )
        return { ...state, num: userInfo.length, userInfo }
      }
      return state
    case constants.UPDATE_USER:
      return {}
    case constants.DELETE_USER:
      return {}
    case constants.UPDATE:
      return { ...state, list: action.payload.list, data: action.payload.data }
    default:
      return state
  }
}
