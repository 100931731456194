import React from 'react'
export interface IUserContext {
  [key: string]: any
}

const UserCtx = React.createContext<IUserContext>({})

const ThemeCtx = React.createContext<IUserContext>({})

export { UserCtx, ThemeCtx }
