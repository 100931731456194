import { lazyLoad } from '@/component/utils'

export const Forgot = lazyLoad({
  loader: () => import(/* webpackChunkName: 'forgot' */ './forgot')
})

export const StandardLogin = lazyLoad({
  loader: () => import(/* webpackChunkName: 'login' */ './standLogin/login')
})

export const Register = lazyLoad({
  loader: () => import(/* webpackChunkName: 'login' */ './register')
})
