import React, { Component, Suspense } from 'react'

import { lazyLoad } from '@/component/utils'
// 全局控制是否需要token的相关变量
import { needToken } from '@/constants'
import { RouteObject } from 'react-router-dom'
import { MarkdownPreview } from './home/entry'
class MobilePage extends Component {
  render() {
    return (
      <Suspense fallback={null}>
        <MarkdownPreview />
      </Suspense>
    )
  }
}

// 后台管理中心布局
export const MobileHome = lazyLoad({
  loader: () => import(/* webpackChunkName: 'manager' */ './home'),
  needToken: false
})
export const BookContent = lazyLoad({
  loader: () => import(/* webpackChunkName: 'manager' */ './book'),
  needToken: false
})
export const BookDetail = lazyLoad({
  loader: () =>
    import(/* webpackChunkName: 'manager' */ './book/component/BookDetail'),
  needToken: false
})

export const SessionList = lazyLoad({
  loader: () =>
    import(/* webpackChunkName: 'manager' */ './book/sessionList/index'),
  needToken: false
})

export const SessionDetail = lazyLoad({
  loader: () =>
    import(/* webpackChunkName: 'manager' */ './book/component/SessionDetail'),
  needToken: false
})

// 后台的入口

export const mobileRoutes: RouteObject[] = [
  {
    path: '/mobile/test',
    element: <>11</>
  },
  {
    path: '/mobile',
    element: <MobilePage />,
    children: [
      {
        path: 'book',
        element: <BookContent />
      },
      {
        path: 'book/sessionList/:id',
        element: <SessionList />
      },
      {
        path: 'book/session/detail/:id',
        element: <SessionDetail />
      },
      {
        path: 'book/:id',
        element: <BookDetail />
      }
    ]
  }
]
// SessionDetail
