import { withLoginChecked } from '@/hoc/withLogin'
import { AnyObj } from '@/utils'
import React, { ComponentType, Suspense } from 'react'

export type IOption = {
  src: string
}

export type LoaderType<P extends AnyObj = any> = () => Promise<{
  default: React.ComponentType<P>
}>

export interface lazyLoadProps<P extends AnyObj> {
  loader: LoaderType<P>
  needToken?: boolean
}

const noop = () => null

export const lazyLoad = (option: lazyLoadProps<any>): ComponentType => {
  const { loader, needToken } = option
  const Com = React.lazy(loader)
  const ComMid = () => {
    return (
      <Suspense fallback={noop()}>
        <Com />
      </Suspense>
    )
  }
  return needToken ? withLoginChecked(ComMid) : ComMid
}
